import React from "react"
import { v4 as uuidv4 } from "uuid";
import './app.css';

import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from '@aws-amplify/ui-react';


import AssetsList from "./assets/AssetsList"
import NewAsset from "./assets/AddAsset"
import Header from "./Header"
import Navigation from "./Navigation"


class MainContainer extends React.Component {

    state = {
        assets: [
            {
                id: uuidv4(),
                category: "Cash",
                amount: "100000000000",
                active: false
            },
            {
                id: uuidv4(),
                category: "Cash",
                amount: "500000",
                active: true
            }
        ]
    }

    addAsset = (amount,category) => {
      const newAsset = {
        id: uuidv4(),
        amount: amount,
        category: category,
        active: true
      };
      this.setState({
        assets: [...this.state.assets, newAsset]
      });
    }

    delAsset = (id) => {
      this.setState({
        assets: [
          ...this.state.assets.filter(asset => {
            return asset.id !== id;
          })
        ]
      });
    }

    handleAssetChange = (id) => {
        this.setState(prevState => {
            return {
              assets: prevState.assets.map(asset => {
                if (asset.id === id) {
                  return {
                    ...asset,
                    active: !asset.active,
                  }
                }
                return asset
              }),
            }
          })
        }

    render() {
        return (
          <div classname="container">
            {/*socialProviders={['apple', 'facebook', 'google']} */}
            <Authenticator>
            {({ signOut, user }) => (
            <div>
             <div className="navigation"><Navigation />Hello {user.username} <button onClick={signOut}>Sign out</button></div>
             <div className="header"><Header /></div>
             <div className="content">
              <NewAsset
              addAsset addAssetProps={this.addAsset}
              />
              <AssetsList 
              assets={this.state.assets} 
              handleChangeProps={this.handleAssetChange} 
              deleteAssetProbs={this.delAsset}
              /></div>
            </div>
             )}
            </Authenticator>
          </div>
        );
      }
}
export default MainContainer