import React from "react"
import Asset from "./Asset"

class AssetsList extends React.Component {
  render() {
    return (
      <div>
         <h3>List of Assetsd</h3>
        <ul>
          {this.props.assets.map(asset => (
            <Asset 
            key={asset.id} 
            asset={asset} 
            handleChangeProps={this.props.handleChangeProps} 
            deleteAssetProps={this.props.deleteAssetProbs}
            />
          ))}
        </ul>
      </div>
    )
  }
}

export default AssetsList