import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import MainContainer from "./components/MainContainer"

import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <MainContainer />
  </StrictMode>
);