import React, { Component } from "react"

class AddAsset extends Component {

    state = {
        category: "Cash",
        amount: ""
    }   

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
          })
      };

    handleSubmit = e => {
        e.preventDefault();
        this.props.addAssetProps(this.state.amount,this.state.category);
        this.setState({
            category: "Cash",
            amount: ""
          });
    };


    render() {
        return (
            <div>
                <h3>Add new Asset</h3>
                <form onSubmit={this.handleSubmit} className="form-container">
       
                    <select 
                    className="input-select"
                    name="category"
                    value={this.state.amount}
                     onChange={this.onChange}
                    >
                        <option value="Cash">Cash</option>
                        <option value="Stocks">Stocks</option>
                    </select>
               
                    <input 
                        className="input-text"
                        type="text" 
                        name="amount" 
                        placeholder="Enter Amount" 
                        value={this.state.amount}
                        onChange={this.onChange}
                        />
                    <button className="input-submit">Add Asset</button>
                </form>
            </div>
        )
  }
}
export default AddAsset