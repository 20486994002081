import React from "react"

const Header = () => {
  return (
    <header>
    <h1
      style={{
        fontSize: "3rem",
        fontWeight: "600",
        marginBottom: "2rem",
        lineHeight: "1em",
        color: "#ececec",
        textTransform: "lowercase",
        textAlign: "center",
      }}
    >
      Where is my Money?
    </h1>
  </header>
  )
}

export default Header