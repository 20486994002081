import React from "react"

class Asset extends React.Component {
  render() {
    return <li>
        <input type="checkbox"
         onChange={() => this.props.handleChangeProps(this.props.asset.id)}
         checked={this.props.asset.active}
        /> 
        <button onClick={() => this.props.deleteAssetProps(this.props.asset.id)}>Delete</button> 
        {this.props.asset.category}: {this.props.asset.amount}</li>
  }
}

export default Asset